.services__container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem 4rem;
}

.card.service{
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.service__icon{
    background: var(--color-primary);
    padding: 0.6rem;
    border-radius: var(--border-radius-3);
    font-size: 1.5rem;
    color: var(--color-white);
    transition: var(--transition);
}

.service:hover .service__icon {
    background: var(--color-light);
    color: var(--color-primary);
}

.service h4{
    margin: 0.7rem;
}

/* Media query for tablets */
@media screen and (max-width: 1024px) {
    .card{
        max-width: 46vw;
    }
    .services__container{
        gap: 1.5rem;
    }
}


/* Media query for phones */
@media screen and (max-width: 600px) {
    
    .card{
        max-width: 90vw;
        padding-inline: .5rem;
    }
    .services__container{
        grid-template-columns: 1fr;
        gap: 1.2rem;
        margin-inline: none;
    }
    .card.service{
        gap: 1.2rem;
    }
}